<template>
<div class="body">
  <nav class="navbar navbar-expand-lg bgColor" ref="home">
      <div class="container">
         <a class="navbar-brand" href="/laureates">
            <img width="70" height="70" src="../../assets/AfCFTA Logo.png" alt="">
            <div class="logo_text">
               <p>AfCFTA</p>
               <p>CARAVAN</p>
            </div>
         </a>
         <button class="navbar-toggler"
            style="border:none; color:#000; outline:none; background:transparent; font-size:24px"
            type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
         <i class="fas fa-bars fa-2x" style="font-size:24px" ></i>
         </button>
         <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ml-auto">
               <li class="nav-item">
                  <a :class="[active == 'home' ? '_active' : '']" @click.prevent="goto('home')" class="nav-link"  aria-current="page" href="#">Home</a>
               </li>
                <li class="nav-item">
                  <a :class="[active == 'about' ? '_active' : '']" @click.prevent="goto('about')" class="nav-link"  aria-current="page" href="#">About Us</a>
               </li>
                <li class="nav-item">
                  <a :class="[active == 'quotes' ? '_active' : '']" @click.prevent="goto('quotes')" class="nav-link"  aria-current="page" href="#">Quotes</a>
               </li>
                <li class="nav-item">
                  <a :class="[active == 'winners' ? '_active' : '']" @click.prevent="goto('winners')" class="nav-link"  aria-current="page" href="#">Winners</a>
               </li>
               <li class="nav-item">
                  <a :class="[active == 'finalists' ? '_active' : '']" @click.prevent="goto('finalists')" class="nav-link"  aria-current="page" href="#">Finalists </a>
               </li>
               <li class="nav-item">
                  <a class="nav-link"  @click.prevent="goto('semi')" :class="[active == 'semi' ? '_active' : '']" href="#">Semi-Finalists</a>
               </li>
                <li class="nav-item">
                  <a class="nav-link"  @click.prevent="goto('partners')" :class="[active == 'partners' ? '_active' : '']" href="#">Partners</a>
               </li>
            </ul>
         </div>
      </div>
   </nav>
  <div class='row __top_bar'>
    <div class="container">
          <div class="row __sm">
            <div class='col-md-8'>
                <div class='-subject-'>
                  <h2>AfCFTA</h2>
                  <h2>Caravan Prize </h2>
                  <h2>Finalists</h2>
                </div>
            </div>
            <div class="col-md-4 __arrow">
                <router-link to="/">
                  <span class="mr-2">Jury Announcements</span> <i class="fas fa-arrow-right "></i>
                </router-link>
            </div>
          </div>
      </div>
    </div>

   <div class='circle-divider___inverse'>
   </div>
   <div class='container desktop' id="__desktop" ref="about">
      <div class='row'>
         <div class='col-md-4 -heading-'>
            <div class='-heading-margining-'>
               <table>
                  <tr>
                     <p >About Us </p>
                  </tr>
                  <tr><button class='-bar-'></button></tr>
               </table>
            </div>
         </div>
         <div class='col-md-6 -body-'>
            <p class='__about__us__sub'>AfCFTA</p>
            <p>&nbsp;</p>
            <p>
               African Continental Free Trade Area (AfCFTA) is a flagship project of Agenda 2063 of the African Union — Africa’s own development vision. It was approved by the 18th ordinary Session of the Assembly of Heads of State and Government, held in Addis Ababa, Ethiopia in January 2012 which adopted the decision to establish a Continental Free Trade Area. This initiative whose immediate implementation would provide quick wins, impact on socio-economic development and enhance confidence and the commitment of Africans as the owners and drivers of Agenda 2063.
            </p>
            <p>&nbsp;</p>
            <p>
               The AfCFTA aims at accelerating intra-African trade and boosting Africa’s trading position in the global market by strengthening Africa’s common voice and policy space in global trade negotiations. The AfCFTA Agreement was signed on 21st March 2018 in Kigali, Rwanda, came into force on 30 May 2019. The scope of the AfCFTA Agreement includes the Protocol on Trade in Goods, the Protocol on Trade in Services, and the Protocol on Rules and Procedures on the Settlement of Disputes, the Protocol on Investment, the Protocol on Intellectual Property Rights, the Protocol on Competition, the Protocol on Digital Trade, and the Protocol on Women and Youth in Trade.
            </p>
         </div>
         <div class='col-md-2'>
         </div>
      </div>
      
      <div class='row'>
         <div class='col-md-4 -heading-'>
         </div>
         <div class='col-md-6 -body- mt-5'>
            <p class='__about__us__sub'>AfCFTA Caravan Prize</p>
            <p>&nbsp;</p>
            <p>
              The Prize is a component of the broader Caravan Initiative, which itself is a flagship of the AU-led 4D Initiative.
              The Caravan platform aims to provide extensive soft infrastructure to boost the capacity of SMEs and startups in
              Africa to expand beyond their home country. It is at the base of the ProPer Network, a supply chain quality
              assurance program created to ensure that the “Rules of Origin" system at the heart of AfCFTA works for both
              governments and businesses. Caravan also powers the “jobs in green tech" program, the Digital Green Corridor,
              designed to promote a trade & investment corridor for Africa's clean tech entrepreneurs to benefit from AfCFTA
              and the ongoing global green shift simultaneously
            </p>
            <p>&nbsp;</p>
            <p>
              The winners of the AfCFTA Caravan Prize will be announced during a gala dinner at the Arab Africa Trade Forum
              on the 3rd of November 2021. Members of the public are warmly invited to join the AfCFTA Laureates online at
              www.bit.ly/AfCFTACaravan to celebrate African entrepreneurial energy at 9:00 pm (GMT+2). 
            </p>
         </div>
         <div class='col-md-2'>
         </div>
      </div>
   </div>
   <!-- qoutes section -->
   <div class="row __main__qoute" ref="quotes">
      <div class="col-md-12">
          <div class="container">
            <div class="row">
                <div class='col-md-3 -heading-' >
                  <div class='-heading-margining-'>
                      <table>
                        <tr>
                            <p style="color:#fff">Quotes</p>
                        </tr>
                        <tr><button class='-bar-'></button></tr>
                      </table>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row __qoute_section">
                      <div class="col-2">
                        <button @click="prev" :class="[prevActive ? '__nav__btn_active' : '']" >
                        <i class="fas fa-angle-left"></i>
                        </button>
                      </div>
                      <div class="col-8 __qoute_text">
                        <p class="__quote">
                            {{ `"${qouteData[index].body}"`}}
                        </p>
                        <p class="__author">
                            {{ qouteData[index].author }}
                        </p>
                      </div>
                      <div class="col-2">
                        <button @click="next" :class="[nextActive ? '__nav__btn_active' : '']">
                        <i class="fas fa-angle-right"></i>
                        </button>
                      </div>
                  </div>
                </div>
                <div class="col-md-2">
                </div>
            </div>
          </div>
      </div>
    </div>
   <!-- qoutes secton ends -->
   <!-- winners -->
   <div class='container'>
   <div class='row block-space' style='border:0px solid red'>
                    <div class='col-md-4 -heading-' ref="jurors">
                        <div class='-heading-margining-'>
                          <table><tr><p>Winners</p></tr><tr><button class='-bar-'></button></tr></table>
        
        <!--<hr>-->
        <div class='judges'>
          <!--HERE (b)-->
        <p  data-target="#carouselExampleIndicators" data-slide-to="0" class="active">Bernie Akporiaye
        </p>
        <p data-target="#carouselExampleIndicators" data-slide-to="1">Purity Gakuo
        </p>
        <p data-target="#carouselExampleIndicators" data-slide-to="2" >Dr. John Afolayan
        </p>
        
        <p data-target="#carouselExampleIndicators" data-slide-to="3">Mildreight Muzendu
        </p>
        <p data-target="#carouselExampleIndicators" data-slide-to="4">Matthieu Gaudemar
        </p>
        <!--HERE (e)-->
        </div>
        </div>
                    </div>
                    <div class='col-md-6 -body- ' style='border:0px dotted red; ' >

                      <!-- <p>Thursday 4th November, 2021. Thursday 4th November, 2021. Thursday 4th November, 2021</p> -->
                      <div class='-judges-flip- '>
                        

          <!--HERE (b)-->

                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-touch="false" data-interval="false">
                          
                          <!-- Judge One -->
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_one ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p class='__name__'>Bernie Akporiaye
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <!--<tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Technical Advisor, 
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>-->
                            
                                                      <tr style="width:100%; border:0px solid orange; position:relative;top:-20px">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p class='company'>MATONTINE 
                                                          <p><small>SENEGAL</small></p>
                                                        </p>
                                                        <button class='__winners__tag'>&#9733 GRAND PRIZE WINNER &#9733</button>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                            
                                                    </table>
                            </div>
                          
                          <!-- Judge Two -->
                            <div class="carousel-item">
                              <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_two ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p class='__name__'>Purity Gakuo
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange;position:relative;top:-20px">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p class='company'>TEKIZO AFRICA
                                                        <p><small>KENYA</small></p>
                                                        </p>
                                                        <button class='__runnerup__tag'>RUNNER UP</button>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <!-- <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>BADEA. 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                             -->
                            
                                                    </table>
                            </div>
                          
                          <!-- Judge Three -->
                            <div class="carousel-item">
                        <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_three ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p class='__name__'> Dr. John Afolayan
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange;position:relative;top:-20px">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p class='company'>MEDICS2YOU 
                                                       <p> <small>NIGERIA</small> </p>
                                                        </p>
                                                                                                                                                                    <button class='__commem__tag'>COMMENDATION PRIZE        WINNER</button>

                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <!-- <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>BADEA. 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr> -->
                            
                            
                                                    </table>
                        
                            </div>
                          
                          <!-- Judge Four -->
                            <div class="carousel-item">
                         <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_four ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p class='__name__'> Mildreight Muzendu
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange; position:relative;top:-20px">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p class='company'>AFTA CARGO 
                                                        <p><small>ZIMBABWE</small></p>
                                                        </p>
                                                        <button class='__commem__tag'>COMMENDATION PRIZE WINNER</button>

                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <!-- <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>BADEA. 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr> -->
                            
                            
                                                    </table>
                            </div>
                          
                          <!-- Judge Five -->
                            <div class="carousel-item">
                              <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_five ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p class='__name__'> Matthieu Gaudemar
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange;position:relative;top:-20px">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p class='company'>KUDOTI 
                                                        <p><small>S. AFRICA</small></p>
                                                        </p>
                                                        <button class='__commem__tag'>COMMENDATION PRIZE WINNER</button>

                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <!-- <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>BADEA. 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr> -->
                            
                            
                                                    </table>
                            </div>
                          
                          
                          
                          </div>
                          <button class="__carousel__btn carousel-control-prev" type="button" data-target="#carouselExampleIndicators" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                          </button>
                          <button class="__carousel__btn carousel-control-next" type="button" data-target="#carouselExampleIndicators" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                          </button>
                        </div>


                        <!--HERE (e)-->
                        
                      </div>
                      <!-- <div id='caro__btn' >
                        <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                          <button class=''>
                            <img src='assets/caret-left.svg'>
                          </button>
                        </a>
                        
                        <a class="right carousel-control" href="#myCarousel" data-slide="next">
                          <button class=''>
                            <img src='assets/caret-right.svg'>
                          </button>
                        </a>
                      </div> -->
                       
                        </div>
                        <div class='col-md-2'>
                          
                                      </div>
                        </div>
                        </div>
   <!-- winners wends -->
   <!-- finalists  -->
   <div class='row __page__margin' ref="finalists" >
            <div class="col-md-12">
               <div class="container">
                    <div class="row">
                        <div class='col-md-12'>
                            <div class="_finalists">
                                <h2>Finalists</h2>
                                <div class="__badding">

                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="row">
                     <div class='col-md-12 -body-'>
                        <div class="block-1">
                           <div class="row">
                              <div class="col-md-4 __list">
                                 <div>
                                    <h3>Bernie Akporiaye</h3>
                                    <p>Matontine</p>
                                 </div>
                                 <div>
                                    <h3>Dr. John Afolayan</h3>
                                    <p>Matontine</p>
                                 </div>
                                 <div>
                                    <h3>Foku  H. Bertin</h3>
                                    <p>WhatsNea</p>
                                 </div>
                                      <div>
                                    <h3>Jean L. Lobe</h3>
                                    <p>Waspito</p>
                                 </div>
                              </div>
                              <div class="col-md-4 __list">
                                 <div>
                                    <h3>Matthieu Gaudemar</h3>
                                    <p>Kudoti</p>
                                 </div>
                                 <div>
                                    <h3>Piwai Nigel Chilasha</h3>
                                    <p>
                                       Alley Capital Group Ltd
                                    </p>
                                 </div>
                                 <div>
                                    <h3>Tapuwa Ndongwe</h3>
                                    <p>Wecol Ltd</p>
                                 </div>
                              </div>
                              <div class="col-md-4 __list">
                                 <div>
                                    <h3>Dennis O.Onkundi</h3>
                                    <p>Tekizo Africa Ltd</p>
                                 </div>
                                 <div>
                                    <h3>O.Sian Tuedon</h3>
                                    <p>PostOff</p>
                                 </div>
                                 <div>
                                    <h3>Mildreight Muzendu </h3>
                                    <p>Africa Free Trade Area</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                    </div>
                  </div>
               </div>
            </div>
         </div>

         <div class='row __page__margin' ref="semi">
            <div class="col-md-12">
                <div class="container">
                    <div class="row" >
                    <div class='col-md-12'>
                        <div class="_finalists">
                                <h2>Semi-Finalists</h2>
                                <div class="__badding">

                                </div>
                            </div>
                        </div>
                        <div class='col-md-12 -body-'>
                            <div class="row">
                                <div class="col-md-4 __list">
                                    <div>
                                    <h3>Langton Chirinda</h3>
                                    <p>Gundi Consulting</p>
                                    </div>
                                    <div>
                                    <h3>Alex Mativo</h3>
                                    <p>Nanasi</p>
                                    </div>
                                    <div>
                                    <h3>Abdullahi M.Bature</h3>
                                    <p>Schoola</p>
                                    </div>
                                    <div>
                                    <h3>Andre Hackett</h3>
                                    <p>School of New Africa</p>
                                    </div>
                                </div>
                                <div class="col-md-4 __list">
                                    <div>
                                    <h3>Rukudzo Chikonye</h3>
                                    <p>Agora</p>
                                    </div>
                                    <div>
                                    <h3>Serge O.K EKOTTO</h3>
                                    <p>Yaddoo Pay</p>
                                    </div>
                                    <div>
                                    <h3>Edem Adjamagbo</h3>
                                    <p>
                                        Samoa
                                    </p>
                                    </div>
                                    <div>
                                    <h3>Gugulethu Nyathi</h3>
                                    <p>Ribbon Blockchain</p>
                                    </div>
                                </div>
                                <div class="col-md-4 __list">
                                    <div>
                                    <h3>Roy Jere</h3>
                                    <p>Clean Cooking Technology Solution-Kainos Afro-Green Energy</p>
                                    </div>
                                    <div>
                                    <h3>10.Apegu-Eolu J.Nelson </h3>
                                    <p>Transforming Soil Health through Biodynamics Agriculture for food Security and Wealth</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
   <!--CONTAINER ENDS-->
   <div class="circle-divider"></div>
   <div class='continer'>
      <div class='row block-space -partners-' ref="partners">
         <div class="container">
           <div class="row">
             <div class='col-md-4 -heading-'>
            <div class='-heading-margining-'>
               <table>
                  <tr>
                     <p class='-p-'>Partners</p>
                  </tr>
                  <tr><button class='-bar-'></button></tr>
               </table>
            </div>
         </div>
         <div class='col-md-6 -body-' style='border:0px solid red'>
         </div>
         <div class='col-md-2'>
         </div>
           </div>
         </div>
      </div>
   </div>
   <div class='block-4 containr' style='background-color: white;' >
      <div class='big-screens'>
         <table class='table  ___partners___'>
            <tr>
               <td class='__empty'> </td>
               <td>
                  <div class='-partners-emblem-afcta-'>
                  </div>
               </td>
               <td>
                  <div class='-partners-emblem-au-'>
                  </div>
               </td>
               <td class='__empty'> </td>
            </tr>
            <tr>
               <td class='__empty'> </td>
               <td>
                  <div class='-partners-emblem-sankoree-'>
                  </div>
               </td>
               <td>
                  <div class='-partners-emblem-champs-'>
                  </div>
               </td>
               <td class='__empty'> </td>
            </tr>
            <tr>
               <td class='__empty'> </td>
               <td>
                  <div class='-partners-emblem-badea-'>
                  </div>
               </td>
               <td>
                  <div class='-partners-emblem-afreximbank-'>
                  </div>
               </td>
               <td class='__empty'> </td>
            </tr>
         </table>
      </div>
      <table class='table rights' style='color:black; position:relative;'>
         <tr>
            <td style='width:100%'>
               <p> <small> &copy; {{ getFullYear }} AfCFTA Caravan Prize. All rights reserved.  </small>         </p>
            </td>
         </tr>
      </table>
   </div>
</div>
</template>

<script>
import $ from 'jquery'
$(window).scroll(function() {
    $('nav').toggleClass('scrolled',$(this).scrollTop() > 0)
})
export default {
  name:'jury-announcement',
  data() {
    return {
      active: 'home',
      index: 0,
      nextActive: true,
      prevActive: false,
      qouteData:[
        {
          id:1,
          body:'If there is one hope that animates everything we do as a Secretariat, it is that AfCFTA must become the most inclusive and most digital Free Trade Area & Process in the World. By focusing on SMEs, digital innovation, gender & equitable transformation, the AfCFTA Caravan Prize will serve as a continental beacon for Agenda 2063. I celebrate the Prize Laureates for sharing our vision, and thank the Jury and Partners for stepping up to the challenge.',
          author:'~ H.E. Wamkele Mene -  Secretary General, AfCFTA Secretariat '
        },
        {
          id:2,
          body:"Africa cannot take advantage of AfCFTA to industrialise and eradicate poverty until three things happen: SMEs absorb tech to boost productivity, wealth from productivity growth is equitably shared, and the scale of growth becomes regional. The AfCFTA Caravan Laureates exemplify how we can achieve all 3 goals, hence our enthusiastic support as BADEA for this initiative.",
          author:'~ Dr. Sidi Ould TAH - Director General, Arab Bank for Economic Development in Africa (BADEA) '
        },
        {
          id:3,
          body:"Of all the flagships meant to take Africa to Agenda 2063, AfCFTA is today the most critical. But AfCFTA itself must have flagships. I think that there is no better candidate than the Caravan Initiative, of which the AfCFTA Caravan Prize is a major component. Caravan's emphasis on youth, gender, equity & technology provides all the pillars for erecting a vision of AfCFTA that all sections of African society can rally behind. It is as much a banner as a flagship.",
          author: '~ H.E. Mahamadou Issoufou - Former President of Niger & African Union AfCFTA Champion'
        },
      ]
    }
  },
  computed: {
    getFullYear() {
      return new Date().getFullYear()
    }
  },
  methods: {
    next() {
      if(this.index > 2) {
        this.index = 0;
        return;
      }
      this.index = this.index + 1
      this.nextActive = true
      this.prevActive = false
    },
    prev() {
      if(this.index < 0) {
         this.index = 0
         return;
      }
      this.index = this.index - 1
      this.prevActive = true
      this.nextActive = false
    },
    goto(refName) {
      this.active = refName
      var element = this.$refs[refName];
      var top = element.offsetTop - 110;
      console.log('top', top)
      window.scrollTo(0, top);
    }
  },
  mounted() {
    
  }
}
</script>

<style lang="scss" scoped>
.bgColor.scrolled{
    background-color: #fff;
    margin-bottom: 0px!important;
}
nav{
  width: 100%;
  top:0;
  z-index: 1000;
  position: fixed;
  background-color: #fff!important;
}
.body{
    background-color: #fff;
}
._active{
  color:rgb(128, 99, 4)!important;
}
.-subject-{
    font-family: Lust; 
    color:rgb(165, 118, 64); 
    margin-bottom: .2em; 
    h2 {
        font-size: 7em;
        margin:0px!important;
        padding:0px!important;
        list-style: none;
        line-height: 98%;
    }
}
@font-face {
    font-family:Lust;
    src: url(../../assets/caravan_quiz/assets/DMSerifDisplay-Regular.ttf);
}
@font-face {
    font-family:Eina-bold;
    src: url(../../assets/caravan_quiz/assets/Eina02-Bold.ttf);
}
@font-face {
    font-family:Inter;
    src: url(../../assets/caravan_quiz/assets/Inter-Regular.ttf);
}
@font-face {
    font-family:Circular;
    src: url(../../assets/caravan_quiz/assets/CircularStd-Book.otf);
}
.body{
    padding: 0;
    margin: 0;
    background-color: #EDEDED;;
   
}
._active{
  color:rgb(128, 99, 4)!important;
}
.-subject-{
    font-family: Lust; 
    color:rgb(165, 118, 64); 
    margin-bottom: .2em; 
    h2 {
        font-size: 7em;
        margin:0px!important;
        padding:0px!important;
        list-style: none;
        line-height: 98%;
    }
}
.__arrow{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    a{
        color:#ADADAD;
        float: right;
        text-decoration: none;
        font-size: 1.2em;
    }
    a:hover{
        color:#ADADAD;
    }
}
h1{color:white}
header{position:fixed; top:0px; width: 100%; height:45;background:transparent; z-index:5}
 .logo{background-image: url('../../assets/caravan_quiz/assets/AfCFTA-CARAVAN-4.png'); background-repeat:no-repeat; background-position: center;
 background-color: transparent; height: 68px;
background-size:cover;
width: 102px;position:relative;top: 15px;}
.-partners-emblem-afcta-, .-partners-emblem-au-, .-partners-emblem-sankoree-, .-partners-emblem-champs-,.-partners-emblem-badea-, .-partners-emblem-afreximbank-{background-repeat:no-repeat; background-position: center;
    background-color: transparent; 
    background-size:cover;
    position:relative;}
    .-partners-emblem-afcta-{ background-image: url('../../assets/caravan_quiz/assets/new_AfCFTA-logo_1.32a017a8.png');height: 90px;width: 90px; }
    .-partners-emblem-au-{ background-image: url('../../assets/caravan_quiz/assets/auweblogo-en.d2ac7604.png'); height: 55px;width: 158px;}
    .-partners-emblem-sankoree-{ background-image: url('../../assets/caravan_quiz/assets/7 2B.05702b7b.png'); height: 100px;width: 102px;}
    .-partners-emblem-champs-{ background-image: url('../../assets/caravan_quiz/assets/afrochamps.jpeg'); height: 60px;width: 170px; }
    .-partners-emblem-badea-{ background-image: url('../../assets/caravan_quiz/assets/badea.png'); height: 90px;width: 90px; }
    .-partners-emblem-afreximbank-{ background-image: url('../../assets/caravan_quiz/rsz_11afrexim.jpg'); height: 90px;width: 90px; }
.-heading-margining- {width:70%; }
.judges, .gala-date {text-align: left;font-size: 14px; font-family: Eina-bold; position:relative; }
/* .judge-highlight{color:rgb(128, 99, 4); font-size: 16px;} */
.-bar-{height:7px;width:50px;background-color: rgb(128, 99, 4);border:none;position:relative;top:-25px}
.-partners- .-bar-{background-color:black;}
.container{top:20px}
.-body-,.block-4 p{color:black; font-family:Inter;
    font-size: 16px; text-align: left;}
.block-space{padding-top: 7em;}
.-heading- {font-family: Lust; font-size: 29px;color:black;text-align: left; }
.-partners-{background-color: white;position:relative;width: 100%
    ;margin-right: 0px;margin-left: 0px;z-index: 3;color:rgb(95, 91, 91);height: auto; padding: 40px;}
    .block-4 p{color:black; font-family:Inter;
        font-size: 17px; }
        .-heading-margining- .-p-{color: black;}
    /* $size:45px; */
    .circle-divider{background-color: rgb(255, 255, 255);width:100%; height:70px; border-radius: 50%;position: relative;top:2.30em;z-index: 2;}

    .circle-divider___inverse{background-color: rgb(255, 255, 255);width:100%; height:70px; border-radius: 50%;position: relative;top:-2.0em;z-index: 2;}
    .raph{font-family: Eina-bold;}
.raph span{color:rgb(128, 99, 4);font-family: Eina-bold;}
.-judges-flip-{background-color: #fff;height:350px;width:100%;border-radius: 12px; }
.-judges-flip- .__carousel__btn{border-radius: 100px;width:40px;height:40px;border:2px solid black;background-color: #000; top:7em}  
#-caret-2{float:right} 
.judge{background-repeat:no-repeat; background-position: center;
    background-color: transparent; 
    background-size:cover;
    position:relative; border-radius:100px ; margin-top:20px}
    .judge_one{background-image: url('../../assets/caravan_quiz/assets/bernie.jpg');height: 140px;width: 140px; }
    .flex{align-content: space-between;  }
    .-just-{display:flexbox;justify-content: center;}
    ._4{font-size:270px; font-weight:600; color:#262626; font-family: Arial Black; line-height: 80%;}
    .November{color:seashell;height:200px;width:165px;border:1px solid #fff;font-size: 60px;word-break: break-all;font-family: Eina-bold;font-weight:600;line-height: 80%;position: relative;right:100px;}
    .__4{background-repeat:no-repeat; background-position: center;
        background-color: transparent; 
      /* background-size: cover; */
        position:relative;  background-image: url('../../assets/caravan_quiz/assets/4--.png');height: 235px;width:352px;border: 0px solid red; }
        .navbar{background:transparent;border:none;
          padding-top: 3em;
        }
        .navbar-expand-lg .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{color:rgb(128, 99, 4)}
        .navbar-nav li a{
          color:#000;
          font-size: 1.2em;
        }
        .navbar-nav li{
          padding-left: 1em;
        }
        .navbar-nav {
          margin-right: 17%;
        }
        .container__{
            margin:25px;
            width:350px;
            height: 200px;;
            border: 0px dotted red;
            }
            .child{
                width:50px;
                height:50px;
                background:red;
                position:absolute;
                top:50%;
                left:50%;
                margin: -25px 0 0 -25px;
            }
@media screen and (max-width: 768px) {
  .__top_bar{
      padding-top: 11em!important;
      padding-bottom: 5em;
    }
    #__desktop{
      padding-top: 8em;
    }
    .row.__page__margin{
        padding-top: 3em;
        padding-bottom: 1.5em;
    }
   .navbar{
          padding-top: 1.5em;
          padding-bottom: 1em;
          background-color:#000;
        }
    .-subject-{
      padding-top:1em;
    }
    .__arrow{
        display: flex;
        padding-top: 1em;
        justify-content: flex-start;
        align-items: flex-start;
        i{
            float: left!important;
        }
    }
    .collapse .navbar-collapse{
      background-color:#000;
      color:#fff;
    }
    .__footer{
      text-align: center;
    }
    ._logo_s{
      margin-bottom: 2em;
    }
    ._finalists{
        p{
            font-size: 1.0em;
        }
        h2{
            font-size: 2.5em;
    }
    .__last{
        margin-bottom: 1em;
        font-family:Lust;
    }
}
}
.__main__qoute{
  margin-top: 8em;
  background:#000;
  padding-top:5em;
  padding-bottom:5em;
}
.navbar-brand{
  display: flex;
}
.logo_text{
  margin-left:.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p{
    margin-bottom: -10px;
    color: #000;;
    font-size: .9em;
    font-family:Inter;
  }
}
.__footer{
  background-color: #fff;
  padding-top:3em;
  padding-bottom: 1em;
  font-family:Inter;
  font-size: 1em;
}
#caro__btn{position: relative; top:-10em }
.judge_two{background-image: url('../../assets/caravan_quiz/assets/purity.jpg');height: 140px;width: 140px; }
.judge_three{background-image: url('../../assets/caravan_quiz/assets/john.jpg');height: 140px;width: 140px; }
.judge_four{background-image: url('../../assets/caravan_quiz/assets/mild.jpg');height: 140px;width: 140px; }
.judge_five{background-image: url('../../assets/caravan_quiz/assets/matt.jpg');height: 140px;width: 140px; }

.__qoute_section{
  width: 100%;
  font-family:Inter;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  .__qoute_text{
    padding:1em;
  }
  .__quote{
    color:rgb(128, 99, 4);
    text-align: center;
    font-style: italic;
    line-height: 2em;
  }
  .__author{
    color:#fff;
    padding-top:1em;
  }
  button{
    background-color: #262626;
    outline:none;
    border:none;
    font-size: 1.2em;
    font-family:Inter;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff;
  }
}
.__nav__btn_active {
  background-color: #fff!important;
  color:#000!important;
}
.__main__qoute{
  margin-top: 8em;
}
.__gala{
  margin-bottom:4em;
}
.__side_nav{
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
  ul li {
    font-size: 14px;
    font-family:Inter;
    padding-bottom: 2em;
    button{
      background-color: transparent;
      border:none;
      outline:none;
      color:#fff;
      font-size: 14px;
    }
  }
}
.desktop{
  padding-top: 3em;
}
.-judges-flip- td p{color:black}
.judges p:hover{color: rgb(128, 99, 4);}
.carousel-control-next-icon, .carousel-control-prev-icon{width:15px; height:15px;color:black}
.___partners___ td{width:25%; border:0px solid red; }
.___partners___ td div{margin:auto}
.___partners___{width:100%;border:0px solid red}
.rights td p{margin:auto; text-align: center;}
.the___winners___{border:1px dotted rgb(128,99,4); padding:10px; border-right:none;border-left:none}
.__about__us__sub{color:black; font-family:Eina-bold;font-size:20px}
.__subject___space{background:white;}
.-judges-flip- small{color:grey; position:relative;right:3px}
.__top_bar{
  background: #fff;
  padding-top: 15em;
  padding-bottom: 5em;
}
.__winners{
  padding-top: 8em;
  padding-bottom: 7em;
}
.__page__margin{
    padding-top: 4em;
    padding-bottom: 2em;
}
._finalists{
    color:#575757;
    p{
        font-size: 1.5em;
        display: flex;
        opacity: .5;
        padding-right: .2em;
        padding-bottom: 2em;
    }
    h2{
        font-size: 2.2em;
        font-family:Lust;
        padding-bottom: .5em;
    }
    .__badding{
        background-color: #A37741;
        width:50px;
        height: 4px;
    }
    padding-bottom: 3em;
}
.__list{
    padding-top: .5em;
    padding-bottom: .5em;
    div h3{
        font-size: 1.3em;
        color:#000;
    }
    div p{
        font-size: 1.1em;
        color:#EF5756;
    }
}
@media screen and (max-width: 600px) {
    .__sm{
        width: 90%;
        margin: 0 auto;
    }
    .-subject-{
      
        h2{
            font-size: 4em;
        }
    }
}
.side_bar {
  cursor: pointer;
}
.prize{
  background-color: #A37741;
  color:#000;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}


 .__subject___space{background:white;}
                .-judges-flip- small{color:grey; position:relative;right:3px}
                .-judges-flip- tr{padding:0px;margin:0px}
                .__runnerup__tag{background:rgb(211, 169, 30); width:100%; border-radius:40px;height:40px;font-size:10px;border:none;font-weight:bold}
                .__commem__tag{background-color:transparent; border:1px solid rgb(211, 169, 30); width:100%; border-radius:40px;height:40px;font-size:10px;font-weight:bold}
                 .__winners__tag{background-image:linear-gradient(90deg, #fdc830 20%, #f37335 65%); width:100%; border-radius:40px;height:40px;font-size:10px;border:none}
                 .__name__{font-weight:bold}
                 .company{font-size:12px}
</style>
